<template>
  <div class="index">
    <header>
      <div class="jumbotron text-center hero">
        <div class="title_head">
          <h1>{{ title }}</h1>
          <div class="title_sub">
            <p>
              2004年に横浜心理トレーニングセンター顧問の宇佐見敏夫により開院されました。<br />
              横浜心理トレーニングセンターと同じ建物であるLMC-Gビルの2階で診察を行っています。
            </p>
          </div>
        </div>
      </div>
    </header>

    <article>
      <div style="margin: 60px auto 20px;">
        <h2>施設のご案内</h2>
      </div>

      <div class="container_info">
        <div class="info_box_back umc1">
          <div class="info_box_text">
            <h3>受付</h3>
          </div>
        </div>

        <div class="info_box_back umc2">
          <div class="info_box_text">
            <h3>待合室</h3>
          </div>
        </div>

        <div class="info_box_back umc3">
          <div class="info_box_text">
            <h3>診察室</h3>
          </div>
        </div>
      </div>

      <div class="notice" style="margin-top: 50px;">
        <p class="notice_text">
          申し訳ありません、現在、初診予約までにお時間を頂いております。
        </p>
      </div>

      <div style="margin: 30px auto 20px;">
        <h2>診察時間</h2>
      </div>

      <div>
        <table class="times_box">
          <tr class="thead">
            <th>受付時間</th>
            <th>月</th>
            <th>火</th>
            <th>水</th>
            <th>木</th>
            <th>金</th>
            <th>土</th>
            <th>日</th>
          </tr>
          <tr>
            <td data-label="13:00 〜 19:00">13:00 〜 19:00</td>
            <td data-label="●">●</td>
            <td data-label="●">●</td>
            <td data-label="-">-</td>
            <td data-label="●">●</td>
            <td data-label="-">-</td>
            <td data-label="-">-</td>
            <td data-label="-">-</td>
          </tr>
        </table>

        <p class="notice_text">※完全予約制となっております</p>
      </div>

      <div style="margin: 90px auto 20px;">
        <h2>院長紹介</h2>
      </div>

      <div class="automargin-horizontal leader_container">
        <div class="leader_image"></div>
        <div class="leader_head">
          <div class="text-center">
            <p class="leader_name text-center">宇佐見敏夫</p>
          </div>
        </div>
        <div class="leader_detail_container">
          <div class="leader_detail">
            精神科医 うさみ心のクリニック院長（2004年開設）<br />
            臨床心理士・公認心理師<br />
            <a
              href="http://www.kaiho.mlit.go.jp/03kanku/"
              rel="noopener noreferrer"
              >海上保安庁第3管区</a
            >
            心の健康対策ネットワーク委員会委員長（2006年〜2023年）<br />
            <a
              href="http://www.kaiho.mlit.go.jp/03kanku/"
              rel="noopener noreferrer"
              >海上保安庁第3管区</a
            >
            健康管理医 メンタルヘルス担当（2014年〜2023年）<br />
            <a
              href="https://www.u-tokai.ac.jp/gd-letters/crs-communications/"
              rel="noopener noreferrer"
              >東海大学大学院 文学研究科 コミュニケーション学専攻</a
            >
            講師（2011年〜2018年 心身医学特論、精神医学特論）<br />
            <a
              href="https://www.dcu.ac.jp/school/human/mind/index.html"
              rel="noopener noreferrer"
              >田園調布学園大学 人間科学部心理学科</a
            >
            講師（2019年〜2020年 精神疾患とその治療）<br />
            中央大学法学部法律学科卒業<br />
            中央大学文学部哲学科心理学専攻卒業<br />
            東海大学医学部医学科卒業（1998年）<br />
          </div>
          <p style="padding: 15px;" />
        </div>
      </div>

      <div style="margin: 90px auto 20px;"></div>

      <div class="map">
        <h2>アクセス</h2>
        <div class="google_map_parent">
          <div class="google_map">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d12998.534892983538!2d139.6140142!3d35.4638613!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xd51e58f9ca4a07f8!2z44GG44GV44G_5b-D44Gu44Kv44Oq44OL44OD44Kv!5e0!3m2!1sja!2sjp!4v1612156441170!5m2!1sja!2sjp"
              width="600"
              height="450"
              frameborder="0"
              style="border:0;"
              allowfullscreen=""
              aria-hidden="false"
              tabindex="0"
            ></iframe>
          </div>
        </div>
      </div>
    </article>

    <footer>
      <div class="footer_content">
        <h3>うさみ心のクリニック</h3>
        <p>
          〒220-0073 神奈川県横浜市西区岡野1-15-10 LMC-Gビル 2F
        </p>
      </div>
    </footer>
  </div>
</template>

<script>
export default {
  name: 'Index',

  data() {
    return {
      title: 'うさみ心のクリニック',
      arel: 'noopener noreferrer',
    }
  },
}
</script>

<style scoped="scss">
body {
  margin: 0px;
  padding: 0px;
}

h1 {
  color: #ffffff;
}

@mixin textoption($color: #000, $size: 1rem, $align: left) {
  color: $color;
  font-size: $fSize;
  text-align: $align;
}

a {
  color: #0077ff;
}
a:hover {
  color: #ff9911;
}
a:active {
  color: #ff6600;
}

.automargin-horizontal {
  margin-left: auto;
  margin-right: auto;
}

.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.notice {
  width: 50vw;
  margin: auto;
  padding: 15px;
  border-radius: 10px;
  background-color: rgba(255, 255, 255, 0.8);
}
.notice_text {
  color: #ff3300;
  padding: 6px;
  font-size: 1.2rem;
}

.jumbotron {
  color: #fff;
  width: 100vw;
  height: 300px;
  margin: 0px;
  padding: 0px;
  background: 
        /* linear-gradient( rgba(0, 0, 0, .55), rgba(0, 0, 0, .55) ), */ linear-gradient(
      135deg,
      rgba(246, 255, 0, 0.4),
      rgba(255, 0, 161, 0.2)
    ),
    url('~@/assets/yuri_2.webp');
  background-size: cover;
}

.jumbotron h1 {
  font-size: 2.4rem;
}

.title_head {
  text-align: center;
  vertical-align: middle;
  height: 300px;
  padding-top: 80px;
}

.title_sub {
  text-align: center;
  vertical-align: middle;
  margin-top: 30px;
  padding: 16px;
  /* background-color: rgba(255, 255, 255, .25); */
  background-color: rgba(0, 0, 0, 0.35);
}

.desc {
  color: #ffffff;
}

.container_info {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  padding: 0px;
  margin: 0px auto 0px;
  width: 90vw;
  border: 0px solid #000;
}

.info_box_back {
  height: 260px;
  width: 100vw;
  max-width: 295px;
  text-align: left;
  border: solid #000 1px;
  margin: 20px;
  display: flex;
  align-items: flex-end;
  min-width: 0;
}

.info_box_text {
  width: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  margin: 10px;
  padding: 10px;
  text-align: left;
  border-radius: 10px;
  color: #fff;
}

.info_box_text h3 {
  font-size: 1.3rem;
}

.umc1 {
  /* background: linear-gradient(-45deg, rgba(246, 255, 0, .3), rgba(255, 30, 0, .3)), */
  background: linear-gradient(
      -45deg,
      rgba(246, 255, 0, 0.3),
      rgba(255, 255, 255, 0.1)
    ),
    url('~@/assets/umc1.jpg') no-repeat;
  background-size: 295px 260px;
}
.umc2 {
  background: linear-gradient(
      -45deg,
      rgba(246, 255, 0, 0.3),
      rgba(255, 255, 255, 0.1)
    ),
    url('~@/assets/umc2.jpg') no-repeat;
  background-size: 295px 260px;
}
.umc3 {
  background: linear-gradient(
      -45deg,
      rgba(246, 255, 0, 0.3),
      rgba(255, 255, 255, 0.1)
    ),
    url('~@/assets/umc3.jpg') no-repeat;
  background-size: 295px 260px;
}

.times_box {
  width: 50vw;
  margin: auto;
  border: #000 0px solid;
  padding: 0px;
  background-color: #fff;
}

.thead {
  background-color: #666;
  color: #fff;
}

.times_box th {
  padding: 3px;
}

.times_box td {
  padding: 6px;
}

.leader_container {
  margin: 5px auto 10px;
  width: 50vw;
  border: 0px solid #ccc;
  display: flex;
  justify-content: center;
  flex-flow: column;
  background-color: #fff;
}

.leader_head {
  padding: 10px 0px 30px;
  margin: auto auto;
}

.leader_image {
  background: url('~@/assets/leader.webp') no-repeat;
  background-size: contain;
  width: 100%;
  height: 25vh;
  background-position: center center;
  margin-top: 20px;
}

.leader_name {
  font-size: 1.7rem;
  padding: 2px 0px 2px;
}

.leader_detail_container {
  width: 80%;
  padding: 2px;
  border: solid 0px #000;
  background: #fff;
  margin: auto auto;
  text-align: left;
}

.leader_detail {
  height: 100%;
  line-height: 24px;
  background-position: 0px 0px;
  background-size: 24px 24px; /* line-height に合わせる */
  background-repeat: repeat;
  background-image: linear-gradient(180deg, transparent 23px, #e6e6e6 23px);
  padding: 0px 5px 0px;
}

.leader_detail_gakkai {
  line-height: 1.5rem;
  padding: 6px;
  margin: 14px 0px 14px;
  background-color: #f5f5f5;
}

.map {
  width: 100vw;
  background-color: rgba(255, 255, 255, 0.3);
  margin: 0px;
  padding: 20px 0 70px;
  background-size: cover;
}

.map h2 {
  padding-bottom: 20px;
}

.google_map_parent {
  width: 650px;
  height: 450px;
  margin: 0px auto 0px;
}
.google_map {
  position: relative;
  width: 100%;
  height: 0;
  padding-top: 75%;
}
.google_map iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

footer {
  width: 100vw;
  background-color: #555; /* background: linear-gradient(to bottom, #E4A972, #9941D8); */
  margin: 0px;
  padding: 20px 0px 30px;
  color: #fff;
  display: flex;
  justify-content: center;
}

.footer_content {
  text-align: left;
  margin: 0 auto 0;
  padding: 20px;
}

@media only screen and (max-width: 375px) {
  .jumbotron h1 {
    font-size: 2rem;
  }

  .title_head {
    height: 300px;
    padding-top: 60px;
  }

  .notice {
    width: 80vw;
  }

  .notice_text {
    font-size: 1rem;
  }

  .times_box {
    width: 90vw;
  }

  .leader_container {
    width: 95vw;
  }

  .leader_detail_container {
    width: 90%;
    font-size: 0.9rem;
  }

  .leader_detail {
    line-height: 23px;
    background-size: 23px 23px; /* line-height に合わせる */
    background-image: linear-gradient(180deg, transparent 22px, #ddd 22px);
  }

  .map {
    padding: 20px 0 40px;
  }

  .google_map_parent {
    width: 100%;
    height: 100%;
  }
}
</style>
