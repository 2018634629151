<template>
  <router-view />
</template>

<script>
export default {
  methods : {
    createTitleDesc : function(routeInstance){
         // タイトルを設定
        if(routeInstance.meta.title){
            var setTitle = routeInstance.meta.title + ' | 【公式】うさみ心のクリニック';
            document.title = setTitle;
        } else {
            document.title = '【公式】うさみ心のクリニック'
        }
        // メタタグdescription設定
        if(routeInstance.meta.desc){
            var setDesc = routeInstance.meta.desc + ' | 横浜の心療内科　うさみ心のクリニックの公式Webサイトです。';
            document.querySelector("meta[name='description']").setAttribute('content', setDesc)
        } else {
            document.querySelector("meta[name='description']").setAttribute('content', '横浜の心療内科　うさみ心のクリニックの公式Webサイトです。')
        }
    }
  },
  mounted : function(){
      var routeInstance = this.$route;
      this.createTitleDesc(routeInstance);
  }
}
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

* {
  margin: 0px;
  padding: 0px;
}

body {
    margin: 0px;
    padding: 0px;
    background: linear-gradient(-45deg, rgba(255, 200, 50, .1), rgba(255, 255, 255, .4)),
    url("~@/assets/washi_1.png") repeat;
}

#nav {
  padding: 0px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
