import { createRouter, createWebHistory } from "vue-router";
import Home from "../views/Home.vue";

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
    meta: { title: "【公式】うさみ心のクリニック", desc: "横浜の心療内科　うさみ心のクリニックのWebサイトです。" }
  }
  
];

const router = createRouter({
  history: createWebHistory(),
  routes
});

export default router;
